import React from 'react';
import ReactDOM from 'react-dom';
// import { store } from './utils/state/store';
// import { Provider } from 'react-redux';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
      <App />
    {/* </Provider> */}
  </React.StrictMode>,
  document.getElementById('root')
);